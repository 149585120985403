// import { get, post, put, patch, del } from '@/utils/http.js';
import { get, post } from '@/utils/http.js';

/*
function:获取首页信息(没有token)
author:ysl
time:2020/09/29
*/

export function getLogin(data: any) {
    return post('/user/password/check', data);
}

/*
function:获取页面数据
author:ysl
time:2021/03/16
*/

export function getLoginInfo(data: any) {
    return get('/account/login/info', data);
}
