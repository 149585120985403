































import { Component, Vue } from 'vue-property-decorator';
import { getLogin, getLoginInfo } from '@/api/login/login.ts';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class Login extends Vue {
  data = {
      phone: '',
      password: '',
      loginimg: {
          imgtitle: '',
          imgurl: 'https://c.zikaoj.com/1682565861.543456.png',
          showhead: true
      }
  };

  created() {
      // eslint-disable-next-line eqeqeq
      const queryUrl = this.$route.query.url;
      if (queryUrl) {
          localStorage.setItem('loginUrl', queryUrl.toString());
          this.data.loginimg.showhead = false;
      }
      const loginUrl = localStorage.getItem('loginUrl');
      if (loginUrl) {
          this.data.loginimg.showhead = false;
          getLoginInfo({ login_type: loginUrl }).then((res: any) => {
              if (res.code === 200) {
                  console.log(res);
                  this.data.loginimg.imgtitle = res.data.login_title;
                  this.data.loginimg.imgurl = res.data.crjy_img;
              }
          });
      }

      const myToken = localStorage.getItem('myToken') as string;
      if (myToken !== null && myToken.length > 0) {
          this.$router.push('/courseWork');
      }
  }

  // 回车
  handleKeyDown() {
      this.signIn();
  }

  // 方法
  signIn() {
      if (this.data.phone === '') {
          return this.$message({
              showClose: true,
              duration: 1000,
              message: '账号不能为空',
              type: 'error'
          });
      } else if (this.data.password === '') {
          return this.$message({
              showClose: true,
              duration: 1000,
              message: '密码不能为空',
              type: 'error'
          });
      } else {
          getLogin(this.data).then((res: any) => {
              if (res.code !== 200) {
                  this.$message({
                      showClose: true,
                      duration: 2000,
                      message: res.error,
                      type: 'error'
                  });
                  return;
              }
              // token
              localStorage.setItem('myToken', res.data.my_token);
              // 有效时间
              localStorage.setItem('validEndTime', res.data.valid_end_time);
              // 学期id
              localStorage.setItem('semesterId', res.data.student.semester_id);
              // json对象转换成字符串
              localStorage.setItem('student', JSON.stringify(res.data.student));
              // 字符串转换成json对象
              this.$router.push('/courseWork');
              this.$message({
                  showClose: true,
                  duration: 2000,
                  message: '登录成功',
                  type: 'success'
              });
          }).catch((error: any) => {
              console.log(error);
          });
      }
  }
}
